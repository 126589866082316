export const Networks = {
    auth: {
        login: '/auth/login-affiliate',
        login_social: '/auth/login-social-affiliate',
        get_user: '/auth/getUser',
        verify: '/auth/verify-code-affiliate',
        register: '/auth/register-affiliate',
        forget_password: '/auth/forget-password',
        logout: '/auth/logout',
        change_password: '/auth/change-password-affiliate',
    },
    marketing_channels: {
        get_marketing_channels: '/affiliate/marketing-channels/get-marketing-channels',
        create_marketing_channels: '/affiliate/marketing-channels/create-marketing-channel',
        update_marketing_channels: '/affiliate/marketing-channels/update-marketing-channel',
        remove_marketing_channels: '/affiliate/marketing-channels/remove-marketing-channel',
        get_list_marketing_channels: '/affiliate/marketing-channels/get-list-marketing-channel',
        get_list_marketing_channels_has_revenue: '/affiliate/marketing-channels/get-list-marketing-channel-has-revenue',
    },
    partners: {
        getPartners: '/affiliate/partner/get-partners',
        createPartner: '/affiliate/partner/create-partner',
        updatePartner: '/affiliate/partner/update-partner',
        confirmPartner: '/affiliate/partner/confirm-partner',
        rejectPartner: '/affiliate/partner/reject-partner',
        removePartner: '/affiliate/partner/remove-partner',
        updateStatus: '/affiliate/partner/update-status',
        getOne: '/affiliate/partner/get-one',
    },
    campaign: {
        get_list_product_not_active_open_campaign: '/affiliate/products/get-product-not-active-open-campaign',
        add_product_open_campaign: '/affiliate/campaign/add-product-open-campaign',
        get_list_product_active_open_campaign: '/affiliate/products/get-product-active-open-campaign',
        get_list_categories: '/affiliate/products/get-list-categories',
        get_list_brands: '/affiliate/products/get-list-brands',
        get_list_categories_filter: '/affiliate/products/get-list-categories-filter',
        get_list_brands_filter: '/affiliate/products/get-list-brands-filter',
        remove_product_open_campaign: '/affiliate/campaign/remove-product-open-campaign',
        get_list_products_for_add_campaign_targe: '/affiliate/products/get-list-products-for-add-campaign-targe',
        create_campaign_target: '/affiliate/campaign/create-campaign-target',
        get_campaigns_target: '/affiliate/campaign/get-campaigns-target',
        stop_campaign_target: '/affiliate/campaign/stop-campaign-target',
        stop_campaign_target_partner: '/affiliate/campaign/stop-campaign-target-partner',
        get_detail_campaign_target: '/affiliate/campaign/{id}',
        update_campaign_target: '/affiliate/campaign/update-campaign-target',
        create_campaign_for_contributor: '/affiliate/campaign/create-campaign-for-contributors',
        export_open_campaign: '/affiliate/campaign/export-open-campaigns',
        update_rate_product_open_campaign: '/affiliate/campaign/update-rate-product-open-campaign',
        export_target_campaign: '/affiliate/campaign/export-target-campaign',
        get_campaigns_target_partner: '/affiliate/campaign/get-campaigns-targer-partner',
        get_campaigns_target_contributor: '/affiliate/campaign/get-campaigns-targer-contributor',
        export_open_campaign_contributor: '/affiliate/campaign/export-open-campaign-contributors',
        export_target_campaign_contributor: '/affiliate/campaign/export-target-campaign-contributors',
        get_list_product_active_open_campaign_limit: '/affiliate/products/get-list-product-active-open-campaign-limit',
        get_campaign_for_contributor: '/affiliate/campaign/get-campaign-for-contributor'
    },
    partner: {
        get_partners_by_type: '/affiliate/partner/get-partners-by-type'
    },
    product_links: {
        getProductLinks: '/affiliate/product-links/get-product-links',
        createProductLinkChannel: '/affiliate/product-links/create-product-link-channel',
        setCommission: '/affiliate/product-links/set-commission',
        updateCommission: '/affiliate/product-links/update-commission',
        setCommissionMultiple: '/affiliate/product-links/set-commission-multiple',
    },
    upload: {
        upload_files: '/upload_files'
    },
    payment: {
        get_payments: '/affiliate/payments/get-payments',
        update_payment: '/affiliate/payments/update-payment',
        export_payment: '/affiliate/payments/export-payment'
    },
    request_payment: {
        get_payments: '/affiliate/request-payments/get-request-payments',
        create_payment: '/affiliate/request-payments/create-request-payment',
        update_payment: '/affiliate/request-payments/update-request-payment',
        update_infor_payment: '/affiliate/request-payments/update-infor-request-payment',
        export_payment: '/affiliate/request-payments/export-request-payment'
    },
    collaborator: {
        getCollaborators: '/affiliate/collaborators/get-collaborators',
        createCollaborator: '/affiliate/collaborators/create-collaborator',
        updateCollaborator: '/affiliate/collaborators/update-collaborator',
        removeCollaborator: '/affiliate/collaborators/remove-collaborator',
        updateQuantityClicked: '/affiliate/collaborators/update-quantity-clicked',
        export: '/affiliate/collaborators/export',
    },
    downloadFile: {
        affiliate_service: '/affiliate/exports/download-file'
    },
    order: {
        export: '/affiliate/orders/export',
        get_list_order_link: '/affiliate/orders/get-list-order-links',
        get_order_link_items: '/affiliate/orders/get-order-link-items'

    },
    performance: {
        get_data_for_channel_main_analysis: '/affiliate/performance/get-data-for-channel-main-analysis',
        get_data_bar_chart_for_channel: '/affiliate/performance/get-data-bar-chart-for-channel',
        get_data_round_chart_for_Channel: '/affiliate/performance/get-data-round-chart-for-Channel',
        get_top_product_for_Channel: '/affiliate/performance/get-top-product-for-Channel',
        get_top_partner: '/affiliate/performance/get-top-partner',
        get_performance_for_open_prd_id: '/affiliate/performance/get-performance-for-open-prd-id',
        get_performance_round_for_open_prd_code: '/affiliate/performance/get-performance-round-for-open-prd-code',
        get_performance_top_partner_open_prd_code: '/affiliate/performance/get-performance-top-partner-open-prd-code',
        get_performance_top_contributor_open_prd_code: '/affiliate/performance/get-performance-top-contributor-open-prd-code',
        get_data_for_analysis_product: '/affiliate/performance/get-data-for-analysis-product',
        get_data_main_factor_for_analysis_product: '/affiliate/performance/get-data-main-factor-for-analysis-product',
        get_data_marketing_channel_for_analysis_product: '/affiliate/performance/get-data-marketing-channel-for-analysis-product',
        get_data_top_partner_for_analysis_product: '/affiliate/performance/get-data-top-partner-for-analysis-product',
        export_top_partner_for_analysis_product: '/affiliate/performance/export-top-partner-for-analysis-product',
        get_data_top_product_for_analysis_product: '/affiliate/performance/get-data-top-product-for-analysis-product',
        export_top_product_for_analysis_product: '/affiliate/performance/export-top-product-for-analysis-product',

        get_data_campaign: '/affiliate/performance/campaign-analysis',
        campaign_get_open_and_target: '/affiliate/performance/campaign-get-open-and-target',

        get_partner_analysis_channel: '/affiliate/performance/get-partner-analysis-channel',
        get_partners_has_order: '/affiliate/performance/get-partner-has-order',
        export_analysis_campagin: '/affiliate/performance/export-analysis-campagin',
        export_top_product_main_analysis: '/affiliate/performance/export-top-product-main-analysis',
        export_top_partner_main_analysis: '/affiliate/performance/export-top-partner-main-analysis',
        get_data_bar_chart_stack: '/affiliate/performance/get-data-bar-chart-stack',
        get_data_top_commission_for_bar_chart: '/affiliate/performance/get-data-top-commission-for-bar-chart',
    },
    product: {
        get_list_product_have_order: '/affiliate/products/get-list-product-have-order',
        get_meta_config: '/affiliate/products/get-meta-config',
        get_product_detail_for_popup: '/affiliate/products/get-product-detail-for-popup',
        update_product_description: '/affiliate/products/update-product-description',
        create_short_link: '/affiliate/products/create-short-link',
    },
    dashboard: {
        get_revenue_dashboard: '/affiliate/dashboard/get-revenue',
        statistical: '/affiliate/dashboard/statistical',
        get_data_bar_chart_contributor: '/affiliate/dashboard/get-data-bar-chart-contributor',
        get_top_product_contributor: '/affiliate/dashboard/get-top-product-for-contributor',
        get_top_contributor_dashboard: '/affiliate/dashboard/get-top-contributor-dashboard'
    },
    notices: {
        get_list_notices: '/affiliate/notices/get-list-notices',
        get_number_notices_inactive: '/affiliate/notices/get-number-notice-inactive',
        update_status_notice: '/affiliate/notices/update-status-notice',
        update_all_status_notice: '/affiliate/notices/update-all-status-notice',
    },
    permission: {
        get_list_roles: '/admin/roles/getList',
        get_many_user: '/auth/getManyUser',
        get_one_role: '/admin/roles/getOne',
        update_role: '/admin/roles/update',
        update_role_permission: '/admin/roles/updatePermissions',
        get_user: '/auth/getUser',
        get_role_service: '/setting/role',
        update_role_user: '/auth/updateRoleUser',
    },
    commission: {
        get_commission: '/affiliate/commissions/get-commission',
    }
}