<template>
    <div id="payment-page">
        <div class='container-fluid p-3'>
            <div class='row mb-3'>
                <div class='row'>
                    <div class="col-xl-2 col-md-3 col-sm-12">
                        <InputText :placeholder="$t('paymentCode')" v-model.trim="filters.keyword"/>
                    </div>
                    <div class="col-xl-2 col-md-3 col-sm-12" v-if="this.user?.type != $constant.PARTNER.type_colloborator">
                        <InputText :placeholder="$t('partnerOrContributorName')" v-model.trim="filters.name"/>
                    </div>
                    <div class="col-xl-2 col-md-3 col-sm-12">
                        <Daterangepicker :placeholder="$t('paymentDate')" v-model="filters.time"/>
                    </div>

                    <div class="col-xl-2 col-md-3 col-sm-12">
                        <button type='submit' class='btn btn-success' @click="search">
                            <span>
                                {{ $t('search') }}
                            </span>
                        </button>
                        <button type='submit' class='btn btn-secondary mx-2' @click="clearFilter">
                            <span>
                                {{ $t('clearFilter') }}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div class='col-lg-12'>
                    <div class="card">
                        <div class="card-body px-0 d-flex flex-wrap ">
                            <div class='col-xl-10 col-md-10 col-sm-12 container-status'>
                                <template v-for="(status, index) in statusLists" :key="index">
                                    <button class="btn"
                                        :class="status.id === filters.status ? 'border-bottom__custom' : ''"
                                        @click="changeFilterStatus(status.id)">
                                        <span class="card-title">
                                            {{ $t(status.label) }}
                                        </span>
                                    </button>
                                </template>
                            </div>

                            <div class="col-xl-2 col-md-2 col-sm-12 text-right">
                                <button @click="exportData()" class="btn btn-outline-secondary mx-2">
                                    <span>{{ $t('exportData') }}</span>
                                </button>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table">
                                <thead class="thead-light border">
                                    <tr>
                                        <th scope="col">{{ $t('paymentCode') }}</th>
                                        <th scope="col" v-if="this.user?.type != $constant.PARTNER.type_colloborator">{{ $t('partnerOrContributorName') }}</th>
                                        <th scope="col">{{ $t('createdAtTicket') }}</th>
                                        <th scope="col">{{ $t('processingAt') }}</th>
                                        <th scope="col">{{ $t('price') }}</th>
                                        <th scope="col">{{ $t('paymentNote') }}</th>
                                        <th scope="col">{{ $t('status') }}</th>
                                        <th scope="col">{{ $t('action') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="customtable" v-if="this.entries?.length > 0">
                                    <template v-for="(entry, index) in entries" :key="index">
                                        <tr>
                                            <td v-text="entry.code"></td>
                                            <td v-text="entry.partner_name" v-if="this.user?.type != $constant.PARTNER.type_colloborator"></td>
                                            <td v-text="entry.created_at ? formatDateCTV(entry.created_at, 'DD/MM/YYYY') : null"></td>
                                            <td v-text="entry.payment_date ? formatDateCTV(entry.payment_date, 'DD/MM/YYYY') : null"></td>
                                            <td v-text="numberFormat(entry.amount)"></td>
                                            <td v-text="entry.content_payment"></td>
                                            <td>
                                                <span v-text="entry?.statusName" :class="entry?.status == 1 ? 'label-secondary' : (entry?.status == 2 ? 'label-success' : 'label-danger')"></span>
                                            </td>
                                            <td>
                                                <span class="cursor-pointer" @click="showModalPaymentDetail(entry)">
                                                    <span class="icon_tooltip" :titleCustom="$t('showDetail')"><i class="fas fa-eye text-active mx-2"></i></span>
                                                </span>
                                                <AppPermission :keyPerm="$constant.PERMISSIONS.PER_PAYMENT_MANAGE" :actionPerm="$constant.PERMISSIONS.PER_ACTION_UPDATE">
                                                    <span class="cursor-pointer" @click="showModalPaymentDetail(entry, true)" v-if="entry.status == $constant.PAYMENT.status.wait_for_process && (entry.partner_id == this.user?.id || entry.contributor_id == this.user?.id)">
                                                        <span class="icon_tooltip" :titleCustom="$t('process')"><i class="far fa-edit text-active mx-2"></i></span>
                                                    </span>
                                                </AppPermission>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                            <div id="noData" v-if="this.entries?.length == 0">
                                <span>{{ $t('noData') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Paginate :value="paginate" :record="record" v-if="this.entries?.length > 0"/>
        </div>
        <ModalPaymentDetail :action="this.action" ref="ModalPaymentDetail" @reload="loadData"/>
        <Loading :loading="isLoading" />
    </div>
</template>

<script>
import Daterangepicker from '@/components/Daterangepicker.vue'
import { numberFormat, getStatusPaymentName, formatDateCTV, checkPermission } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import InputText from '@/components/Form/InputText.vue';
import Paginate from '@/components/Paginate.vue';
import SwitchButton from '@/components/Button/SwitchButton.vue';
import ModalPaymentDetail from '@/components/Modal/Payment/ModalPaymentDetail.vue'
import { mapGetters } from 'vuex';
import AppPermission from '@/components/AppPermission.vue';

export default {
    name: 'Payment',
    setup() {
        return { numberFormat, getStatusPaymentName, formatDateCTV, checkPermission };
    },
    components: {
        Daterangepicker,
        Loading,
        InputText,
        Paginate,
        SwitchButton,
        ModalPaymentDetail,
        AppPermission
    },
    data() {
        return {
            isLoading: false,
            record: 100,
            paginate: {
                totalRecord: 1,
                currentPage: 1,
                lastPage: 1
            },
            statusLists: [
                { id: 0, label: 'all' },
                { id: 1, label: 'waitForApprove' },
                { id: 2, label: 'approved' },
                { id: 3, label: 'reject' },
            ],
            filters: {
                status: 0,
                record: 25,
            },
            action: 1
        }
    },
    mounted(){
        this.loadData();
    },
    computed: {
        ...mapGetters({
            entries: 'payment/getPaymentLists',
            user: 'auth/userInfo',
            isLoading: 'isLoading',
            loadedPermission: 'loadedPermission'
        })
    },
    updated(){
        if(!this.isLoading && this.loadedPermission){
            if(!this.checkPermission(this.$constant.PERMISSIONS.PER_PAYMENT_MANAGE, this.$constant.PERMISSIONS.PER_ACTION_LIST)){
                this.$router.push({name: this.$constant.ROUTER.dashboardName});
            }
        }
    },
    methods: {
        changeFilterStatus(status) {
            this.filters.status = status;
            this.loadData();
        },
        async loadData() {
            
            const res = await this.$store.dispatch('payment/fetchPaymentInfo', this.filters);
            this.paginate.totalRecord = res?.result?.data?.total;
            this.paginate.currentPage = res?.result?.data?.current_page;
            this.paginate.lastPage = res?.result?.data?.last_page;
        },
        openDetailPayment(status){
            this.action = status
        },
        search(){
            this.filters.page = 1;
            this.loadData();
        },
        clearFilter(){
            this.filters = {
                status: 0,
                record: 25,
                contributor_name: null,
                keyword: null,
                time: null,
                name: null
            },
            this.loadData();
        },
        showModalPaymentDetail(entry, edit = false){
            this.$refs.ModalPaymentDetail.show(entry, edit);
        },
        async exportData() {
            if (this?.user?.type == this.$constant.PERMISSIONS.TYPE_CONTRIBUTOR) {
                this.filters.name_export = this?.user?.name
            }
            const res = await this.$store.dispatch('payment/exportPayment', this.filters);
            if (res) {
                this.$store.dispatch('setShowModalExport', true)
            }
        },
    },
};
</script>

<style lang="scss" scoped>
// @import "../../assets/scss/partner.scss";
</style>