import { Module } from 'vuex';
import http from '@/services/http';
import { Networks } from '@/services/Networks';
import CONSTANT from '@/common/constant';
import store from '@/store'
interface AuthState {
    auth: [];
    token: string | null,
    roleID: string | null,
    active: string | null,
    type: any,
}

const state: AuthState = {
    auth: [],
    token: null,
    roleID: null,
    active: 'false',
    type: 'null'
};

const auth: Module<AuthState, any> = {
    namespaced: true,
    state,
    mutations: {
        setAuth(state, auth: []) {
            state.auth = auth;
        },
        setToken(state, token: string) {
            state.token = token;
            localStorage.setItem('tokenAffiliate', token);
        },
        setUserRole(state, roleID: string) {
            state.roleID = roleID;
            localStorage.setItem('userRoleAffiliate', roleID);
        },
        setUserActive(state, active: string) {
            active = active ? 'true' : 'false';
            state.active = active;
            localStorage.setItem('userActiveAffiliate', active);
        },
        setUserType(state, type: any) {
            state.type = type ?? 'null';
            localStorage.setItem('userTypeAffiliate', (type ?? 'null'));
        },
    },
    actions: {
        async fetchUserInfo({ commit }) {
            try {
                const response:any = await http.get(Networks.auth.get_user);
                if(response?.code === 200){
                    commit('setAuth', response?.data);
                    commit('setUserRole', response?.data?.role_id);
                    commit('setUserActive', response?.data?.active);
                    commit('setUserType', response?.data?.type);
                    if(response && response.data && response.data.roles) {
                        store.dispatch('setPermissions', response);
                    }
                }
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },
        async login({ commit }, params) {
            try {
                const response:any = await http.post(Networks.auth.login, params);
                if(response?.code === 200 && !response?.error && response?.data){
                    commit('setToken', response?.data?.access_token)
                }
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },
        async loginSocial({ commit }, params) {
            try {
                const response:any = await http.post(Networks.auth.login_social, params);
                if(response?.code === 200 && !response?.error && response?.data){
                    commit('setToken', response?.data?.access_token)
                }
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },
        setToken({commit}, token){
            commit('setToken', token);
        },
        setAuth({commit}, auth){
            commit('setAuth', auth);
        },
        async verify({ commit }, params) {
            try {
                const response:any = await http.post(Networks.auth.verify, params);
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        async register({ commit }, params) {
            try {
                const response:any = await http.post(Networks.auth.register, params);
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },
        setUserRole({commit}, role){
            commit('setUserRole', role);
        },
        async forgetPassword({ commit }, params) {
            try {
                const response:any = await http.post(Networks.auth.forget_password, params);
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },
        setUserActive({commit}, active){
            commit('setUserActive', active);
        },
        setUserType({commit}, type){
            commit('setUserType', type);
        },
        async logout({ commit }, params) {
            try {
                const response:any = await http.post(Networks.auth.logout, params);
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },
        async changePassword({ commit }, params) {
            try {
                const response:any = await http.post(Networks.auth.change_password, params);
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },
    },
    getters: {
        userInfo(state): [] {
            return state.auth;
        },
        token(state) {
            return state.token;
        },
        userRole(state) {
            return state.roleID;
        },
        userType(state) {
            return state.type;
        },
    },
};

export default auth;
