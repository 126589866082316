<template>
    <div id="partner-page">
        <div class='container-fluid p-3'>
            <div class='row'>
                <div class='form-group row'>
                    <div class="col-xl-7 col-md-8 col-sm-12">
                        <div class="row">
                            <div class="col-xl-4 col-md-4 col-sm-12 mb-2">
                                <InputText :placeholder="$t('searchPartner')" v-model="filters.keyword"/>
                            </div>
                            <div class="col-xl-4 col-md-5 col-sm-12 mb-2">
                                <Daterangepicker :placeholder="$t('createdAt')" v-model="filters.created"/>
                            </div>
                            <div class="col-xl-4 col-md-3 col-sm-12 mb-2">
                                <InputSelect :placeholder="$t('accountType')" :options="this.$constant.ACCOUNT_TYPE" v-model="filters.type"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-5 col-md-4 col-sm-12">
                        <div class="row">
                            <div class="col-xl-6 col-md-12 col-sm-12 mb-2">
                                <button type='submit' class='btn btn-success' @click="search">
                                    <span>
                                        {{ $t('search') }}
                                    </span>
                                </button>
                                <button class='btn btn-secondary mx-1' @click="clearFilter">
                                    <span>
                                        {{ $t('clearFilter') }}
                                    </span>
                                </button>
                            </div>
                            <AppPermission :keyPerm="$constant.PERMISSIONS.PER_PARTNER" :actionPerm="$constant.PERMISSIONS.PER_ACTION_CREATE">
                                <div class="col-xl-6 col-md-12 col-sm-12 mb-2 d-flex justify-content-end p-0">
                                    <button type='submit' class='btn btn-primary'>
                                        <router-link :to="this.$constant.ROUTER.createPartner" class="text-white">
                                            <i class="fa fa-plus"></i>
                                            <span class="mx-2">
                                                {{ $t('createPartner') }}
                                            </span>
                                        </router-link>
                                    </button>
                                </div>
                            </AppPermission>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div class='col-lg-12'>
                    <div class="card">
                        <div class="card-body p-0">
                            <div class='col-lg-12 col-sm-12 d-flex flex-wrap container-status'>
                                <template v-for="(status, index) in statusLists" :key="index">
                                    <button class="btn" :class="status.id === filters.status ? 'border-bottom__custom' : ''" @click="changeStatus(status.id)">
                                        <span class="card-title">
                                            {{ $t(status.label) }}
                                        </span>
                                    </button>
                                </template>
                            </div>
                        </div>
                        <div class="table-responsive" v-if="filters.status === this.$constant.PARTNER.status_active">
                            <table class="table">
                                <thead class="thead-light border">
                                    <tr>
                                        <th scope="col">{{ $t('id') }}</th>
                                        <th scope="col">{{ $t('partner') }}</th>
                                        <th scope="col">{{ $t('email') }}</th>
                                        <th scope="col">{{ $t('accountType') }}</th>
                                        <th scope="col">{{ $t('numberOfCampaigns') }}</th>
                                        <th scope="col">{{ $t('joiningDate') }}</th>
                                        <th scope="col">{{ $t('numberOfCollaborators') }}</th>
                                        <th scope="col">{{ $t('createdBy') }}</th>
                                        <th scope="col">{{ $t('status') }}</th>
                                        <th scope="col">{{ $t('action') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="customtable" v-if="this.entries?.length > 0">
                                    <template v-for="(entry, index) in entries" :key="index">
                                        <tr>
                                            <td v-text="entry.id"></td>
                                            <td class="column-name" v-text="entry.name"></td>
                                            <td class="column-email" v-text="entry.email"></td>
                                            <td v-text="getTypePartnerName(entry.type)"></td>
                                            <td v-text="numberFormat(entry?.number_campaign)"></td>
                                            <td v-text="entry.active ? formatDateCTV(entry.active, 'DD/MM/YYYY') : null"></td>
                                            <td v-text="numberFormat(entry?.affiliate_partners_count)"></td>
                                            <td v-text="!entry?.registered_at ? entry?.created_affiliate_by_user?.name : null"></td>
                                            <td>
                                                <span v-if="entry.status == this.$constant.CONTRIBUTOR.status.send_invitation">{{ $t('sendInvitations') }}</span>
                                                <template v-else>
                                                    <!-- <AppPermission :keyPerm="$constant.PERMISSIONS.PER_PARTNER" :actionPerm="$constant.PERMISSIONS.PER_ACTION_UPDATE"> -->
                                                        <SwitchButton v-model="entry.status" @update:modelValue="changeStatusPartner(entry)"/>
                                                    <!-- </AppPermission> -->
                                                </template>
                                            </td>
                                            <td class="nowrap">
                                                <span class="pe-auto" @click="showDetailPartner(entry)">
                                                    <span class="icon_tooltip" :titleCustom="$t('showDetail')"><i class="fas fa-eye text-active mx-2"></i></span>
                                                </span>
                                                <AppPermission :keyPerm="$constant.PERMISSIONS.PER_PARTNER" :actionPerm="$constant.PERMISSIONS.PER_ACTION_UPDATE">
                                                    <span class="" @click="updatePartner(entry)">
                                                        <span class="icon_tooltip" :titleCustom="$t('edit')"><i class="far fa-edit text-active mx-2"></i></span>
                                                    </span>
                                                </AppPermission>
                                                <AppPermission :keyPerm="$constant.PERMISSIONS.PER_PARTNER" :actionPerm="$constant.PERMISSIONS.PER_ACTION_DELETE">
                                                    <span class="" @click="showModalRemove(entry)" v-if="entry.status == this.$constant.CONTRIBUTOR.status.send_invitation">
                                                        <span class="icon_tooltip" :titleCustom="$t('delete')"><i class="fas fa-trash-alt text-active mx-2"></i></span>
                                                    </span>
                                                </AppPermission>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>

                        <div class="table-responsive" v-if="filters.status === this.$constant.PARTNER.status_wait_confirm">
                            <table class="table">
                                <thead class="thead-light border">
                                    <tr>
                                        <th scope="col">{{ $t('id') }}</th>
                                        <th scope="col">{{ $t('partner') }}</th>
                                        <th scope="col">{{ $t('phone') }}</th>
                                        <th scope="col">{{ $t('email') }}</th>
                                        <th scope="col">{{ $t('accountType') }}</th>
                                        <th scope="col">{{ $t('registerDate') }}</th>
                                        <th scope="col">{{ $t('status') }}</th>
                                        <th scope="col">{{ $t('action') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="customtable" v-if="this.entries?.length > 0">
                                    <template v-for="(entry, index) in entries" :key="index">
                                        <tr>
                                            <td v-text="entry.id"></td>
                                            <td class="column-name" v-text="entry.name"></td>
                                            <td v-text="entry.phone"></td>
                                            <td class="column-email" v-text="entry.email"></td>
                                            <td v-text="getTypePartnerName(entry.type)"></td>
                                            <td v-text="entry.registered_at ? formatDateCTV(entry.registered_at, 'DD/MM/YYYY') : null"></td>
                                            <td v-text="getStatusPartnerName(entry.status)"></td>
                                            <td>
                                                <span class="pe-auto" @click="showDetailPartner(entry)">
                                                    <span class="icon_tooltip" :titleCustom="$t('showDetail')"><i class="fas fa-eye text-active mx-2"></i></span>
                                                </span>
                                                <AppPermission :keyPerm="$constant.PERMISSIONS.PER_PARTNER" :actionPerm="$constant.PERMISSIONS.PER_ACTION_UPDATE">
                                                    <span class="" @click="showModalConfirm(entry)">
                                                        <span class="icon_tooltip" :titleCustom="$t('approve')"><i class="fas fa-check-circle text-active mx-2"></i></span>
                                                    </span>
                                                    <span class="" @click="showModalReject(entry)">
                                                        <span class="icon_tooltip" :titleCustom="$t('reject')"><i class="fas fa-times-circle text-active mx-2"></i></span>
                                                    </span>
                                                </AppPermission>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>

                        <div class="table-responsive" v-if="filters.status === this.$constant.PARTNER.status_reject">
                            <table class="table">
                                <thead class="thead-light border">
                                    <tr>
                                        <th scope="col">{{ $t('id') }}</th>
                                        <th scope="col">{{ $t('partner') }}</th>
                                        <th scope="col">{{ $t('phone') }}</th>
                                        <th scope="col">{{ $t('email') }}</th>
                                        <th scope="col">{{ $t('accountType') }}</th>
                                        <th scope="col">{{ $t('registerDate') }}</th>
                                        <th scope="col">{{ $t('status') }}</th>
                                        <th scope="col">{{ $t('reason') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="customtable" v-if="this.entries?.length > 0">
                                    <template v-for="(entry, index) in entries" :key="index">
                                        <tr>
                                            <td v-text="entry.id"></td>
                                            <td class="column-name" v-text="entry.name"></td>
                                            <td v-text="entry.phone"></td>
                                            <td class="column-email" v-text="entry.email"></td>
                                            <td v-text="getTypePartnerName(entry.type)"></td>
                                            <td v-text="entry.registered_at ? formatDateCTV(entry.registered_at, 'DD/MM/YYYY') : null"></td>
                                            <td v-text="getStatusPartnerName(entry.status)"></td>
                                            <td v-text="entry?.reason" style="width: 300px;"></td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div id="noData" v-if="this.entries?.length == 0">
                            <span>{{ $t('noData') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <Paginate :value="paginate" :record="filters.record" @pageChange="pageChange" v-if="this.entries?.length > 0"/>
        </div>
        <ModalDetailPartner ref="modalDetailPartner"/>
        <ModalRemovePartner ref="ModalRemovePartner" @reload="loadData"/>
        <ModalRejectPartner ref="ModalRejectPartner" @reload="loadData"/>
        <ModalConfirmPartner ref="ModalConfirmPartner" @reload="loadData"/>
        <Loading :loading="isLoading" />
    </div>
</template>

<script>
import Daterangepicker from '@/components/Daterangepicker.vue'
import { numberFormat, getStatusPartnerName, getTypePartnerName, clone, formatDateCTV, checkPermission } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import InputText from '@/components/Form/InputText.vue';
import Paginate from '@/components/Paginate.vue';
import SwitchButton from '@/components/Button/SwitchButton.vue';
import ModalDetailPartner from '@/components/Modal/Partner/ModalPartnerDetail.vue';
import ModalRemovePartner from '@/components/Modal/Partner/ModalRemovePartner.vue';
import { mapGetters } from 'vuex';
import InputSelect from '@/components/Form/InputSelect.vue';
import ModalRejectPartner from '@/components/Modal/Partner/ModalRejectPartner.vue';
import ModalConfirmPartner from '@/components/Modal/Partner/ModalConfirmPartner.vue';
import toastr from "toastr";
import AppPermission from '@/components/AppPermission.vue';

export default {
    name: 'Partner',
    setup() {
        return { numberFormat, getStatusPartnerName, getTypePartnerName, clone, formatDateCTV, checkPermission };
    },
    components: {
        Daterangepicker,
        Loading,
        InputText,
        Paginate,
        SwitchButton,
        ModalDetailPartner,
        ModalRemovePartner,
        InputSelect,
        ModalRejectPartner,
        ModalConfirmPartner,
        AppPermission
    },
    data() {
        return {
            isLoading: false,
            paginate: {
                totalRecord: 1,
                currentPage: 1,
                lastPage: 1
            },
            statusLists: [
                {id: this.$constant.PARTNER.status_active, label: 'partner'},
                {id: this.$constant.PARTNER.status_wait_confirm, label: 'requestApproval'},
                {id: this.$constant.PARTNER.status_reject, label: 'reject'},
            ],
            filters: {
                status: this.$constant.PARTNER.status_active,
                record: this.$constant.PAGE.record,
                type: null,
            },
            contentConfirm: '',
            partnerConfirmID: null,
            partnerRejectID: null,
        }
    },
    computed: {
        ...mapGetters({
            entries: 'partners/listPartners',
            detail: 'partners/getDetail',
            isLoading: 'isLoading',
            loadedPermission: 'loadedPermission'
        })
    },
    updated(){
        if(!this.isLoading && this.loadedPermission){
            if(!this.checkPermission(this.$constant.PERMISSIONS.PER_PARTNER, this.$constant.PERMISSIONS.PER_ACTION_LIST)){
                this.$router.push({name: this.$constant.ROUTER.dashboardName});
            }
        }
    },
    mounted(){
        if (this.$route.query && this.$route.query.partner_id) {
            this.filters.keyword = this.$route.query.partner_id;
        }
        if (this.$route.query && this.$route.query.name) {
            this.filters.keyword = this.$route.query.name;
        }
        this.loadData();
    },
    methods: {
        changeStatus(status){
            this.filters.status = status
            this.filters.type = null
            this.loadData()
        },
        async loadData(){
            let params = clone(this.filters);
            if(params.status === this.$constant.PARTNER.status_active){
                params.status = [this.$constant.PARTNER.status_inactive, this.$constant.PARTNER.status_active, this.$constant.PARTNER.status_send_invitation]
            }else{
                params.status = [params.status]
            }
            const res = await this.$store.dispatch('partners/fetchPartners', params)
            this.paginate.totalRecord = res?.data?.data?.total
            this.paginate.currentPage = res?.data?.data?.current_page
            this.paginate.lastPage = res?.data?.data?.last_page
        },
        pageChange(page) {
            this.filters.page = page
            this.loadData()
        },
        search(){
            this.filters.page = 1
            this.loadData()
        },
        showDetailPartner(entry = null){
            this.$refs.modalDetailPartner.show(entry);
        },
        showModalConfirm(entry){
            if(!entry){
                return;
            }
            this.$refs.ModalConfirmPartner.show(entry.id);
        },
        showModalReject(entry){
            if(!entry){
                return;
            }
            this.$refs.ModalRejectPartner.show(entry.id);
        },
        showModalRemove(entry){
            if(!entry){
                return;
            }
            this.$refs.ModalRemovePartner.show(entry.id);
        },
        updatePartner(entry){
            this.$router.push({name: this.$constant.ROUTER.editPartnerName, query: {id: entry.id}});
        },
        clearFilter(){
            this.filters = {
                status: this.$constant.PARTNER.status_active,
                record: this.$constant.PAGE.record,
                type: null,
            }
            this.loadData()
        },
        async changeStatusPartner(entry){
            if(!entry){
                return;
            }
            const res = await this.$store.dispatch('partners/updateStatus', {
                'id': entry.id,
                'status': entry.status
            })
            if(res.error){
                toastr.error(this.$t('msgUpdateError'));
                return;
            }
            toastr.success(this.$t('msgUpdateSuccess'));
        }
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/partner.scss";
</style>