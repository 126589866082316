<template>
    <header class="topbar" data-navbarbg="skin5" id="header-page">
        <nav class="navbar top-navbar navbar-expand-md navbar-dark">
            <div class="navbar-header" data-logobg="skin5">

                <a class="navbar-brand">
                    <b class="logo-icon ps-2">
                        <div class="background-div" :data-bg="getLogoOrSize(true)" :data-bgsize="getLogoOrSize()"></div>
                    </b>
                </a>
                <a class="nav-toggler waves-effect waves-light d-block d-md-none" href="javascript:void(0)"><i
                    class="ti-menu ti-close"></i></a>
            </div>
            
            <div class="navbar-collapse collapse" id="navbarSupportedContent" data-navbarbg="skin5">
                
                <ul class="navbar-nav float-start me-auto">
                    <li class="nav-item d-none d-lg-block">
                        <h3 class="px-3 text-white">{{ routeName }}</h3>
                    </li>
                </ul>

                <ul class="navbar-nav float-end">
                    <AppPermission :keyPerm="$constant.PERMISSIONS.PER_REQUEST_PAYMENT_MANAGE" :actionPerm="$constant.PERMISSIONS.PER_ACTION_CREATE">
                        <li class="nav-item dropdown">
                            <b class="nav-link dropdown-toggle text-muted text-white waves-effect waves-dark pro-pic" id="navbarDropdown" role="button" aria-expanded="false">
                                {{ $t('commission') + ": " + (this.commission ? numberFormat(this.commission) : 0) }}
                            </b>
                        </li>
                    </AppPermission>
                    <Notice/>

                    <li class="nav-item dropdown">
                        <a class="
                                nav-link
                                dropdown-toggle
                                text-muted
                                waves-effect waves-dark
                                pro-pic
                            " href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="../../../public/assets/images/users/1.jpg" alt="user" class="rounded-circle"
                                width="31" />
                        </a>
                        <ul class="dropdown-menu dropdown-menu-end user-dd animated" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item" href="javascript:void(0)">
                                <div class="d-flex">
                                    <img v-if="user?.avatar" :src="user?.avatar" alt="user" class="rounded-circle"
                                        width="42" />
                                    <img v-else src="../../../public/assets/images/users/1.jpg" alt="user" class="rounded-circle"
                                        width="42" />
                                    <div class="mx-2">
                                        <div>{{ user?.name }}</div>
                                        <div>ID: {{ user?.id }}</div>
                                    </div>
                                </div>
                            </a>
                            <div class="dropdown-divider"></div>
                            <router-link :to="this.$constant.ROUTER.userInfor" class="dropdown-item text-dark text-decoration-none">
                                <i class="mdi mdi-account me-1 ms-1"></i>
                                {{ $t('account') }}
                            </router-link>
                            <div class="dropdown-divider"></div>
                            <router-link :to="this.$constant.ROUTER.changePassword" class="dropdown-item text-dark text-decoration-none">
                                <i class="fa fa-lock me-1 ms-1"></i>
                                {{ $t('changePassword') }}
                            </router-link>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="javascript:void(0)" @click="logout">
                                <i class="fa fa-power-off me-1 ms-1"></i>
                                {{ $t('logout') }}
                            </a>
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>
    </header>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import Notice from './Notice.vue';
import logo_default from '../../../public/images/logo_default.png'
import banner from '../../../public/assets/images/banner.png'
import { numberFormat } from '@/utils/utils';
import AppPermission from '@/components/AppPermission.vue';
export default defineComponent({
    name: 'Header',
    props: ['routeName'],
    components: {
        Notice,
        AppPermission
    },
    setup() {
        return { numberFormat };
    },
    data(){
        return {
            logo_default: logo_default,
            banner: banner,
        }
    },
    methods: {
        async logout(){
            if(confirm(this.$t('logoutSystem'))){
                await this.$store.dispatch('auth/logout');
                this.$store.dispatch('auth/setToken', '');
                this.$store.dispatch('auth/setUserRole', '');
                this.$store.dispatch('auth/setUserActive', '');
                this.$store.dispatch('auth/setUserType', '');
                location.replace(this.$constant.ROUTER.login)
            }
        },
        getLogoOrSize(logo = false){
            if(this.user && !this.user?.type){
                return logo ? this.banner : 'cover';
            }else if(this.user && this.user?.type){
                if(this.user?.logo){
                    return logo ? this.user?.logo : 'contain';
                }
            }
            return logo ? this.logo_default : 'cover';
        },
        setLogo(){
            this.$nextTick(() => { 
                document.querySelectorAll('.background-div').forEach((div) => { 
                    let bgImage = div.getAttribute('data-bg'); 
                    let bgSize = div.getAttribute('data-bgsize');
                    if (bgImage) { 
                        bgImage = bgImage.replace(/ /g, '%20');
                        div.style.backgroundImage = `url(${bgImage})`;
                    } 
                    if (bgSize) { 
                        div.style.backgroundSize = bgSize; 
                    }
                }); 
            });
        }
    },
    mounted(){
        this.setLogo();
        this.$store.dispatch('userStore/getCommission');
    },
    computed: {
        ...mapGetters({
            user: 'auth/userInfo',
            commission: 'userStore/getCommission'
        })
    },
    watch: {
        user: {
            handler: function(newData) {                
                this.setLogo();
            },
            deep: true
        },
    },
});
</script>
<style lang="scss" scoped>
@import "../../assets/scss/header.scss";
</style>