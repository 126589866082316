<template>
    <div class='modal fade' ref="EditDescription" id='EditDescription' aria-labelledby='EditDescription' aria-hidden='true'
        data-bs-backdrop='false' tabindex="-1">
        <div class='modal-dialog modal-lg modal-dialog-scrollable'>
            <div class='modal-content p-0'>
                <div class='modal-header border-bottom-0'>
                    <h1 class='modal-title fs-5' v-text="this.product?.product_name ?? null"></h1>
                    <button type='button' class='btn-close' @click.stop="hide"></button>
                </div>
                <div class='modal-body pt-0'>
                    <div class='mt-3 d-flex align-items-center justify-content-between w-100'>
                        <span class="text-bold">{{ this.$t('sampleContent') }}</span>
                        <div>
                            <button class="btn btn-outline-secondary mx-2" @click.stop="copyDescription">
                                <i class="fa fa-copy mx-1"></i>
                                <span>{{this.$t('copyContent')}}</span>
                            </button>
                            <button class="btn btn-outline-secondary" @click.stop="showModalEdit">
                                <i class="fa fa-edit mx-1"></i>
                                <span>{{ this.$t('editContent') }}</span>
                            </button>
                        </div>
                    </div>
                    <div class='mt-3'>
                        <button class="btn" :class="this.templateActiveID == 1 ? 'btn-select' : 'btn-outline-select'" @click="changeTemplate(1)">
                            <span class="text-bold">{{ this.$t('productIntroduction') }}</span>
                        </button>
                        <button class="btn mx-2" :class="this.templateActiveID == 2 ? 'btn-select' : 'btn-outline-select'" @click="changeTemplate(2)">
                            <span class="text-bold">{{ this.$t('productAdvertising') }}</span>
                        </button>
                    </div>
                    <div class='modal-body__content mt-3' id="description" ref="contentToCopy">
                        <div v-html="this.templateActiveID == 2 ? this.product?.description_advertising : this.product?.description"></div>
                    </div>
                    <div class='mt-3'>
                        <h5>
                            <span>{{ this.$t('image') }}</span>
                        </h5>
                        <div ref="imageToCopy">
                            <div class="d-flex" v-if="this.product?.images && this.product?.images?.length" id="image">
                                <div class="product-img" v-for="(img, index) in this.product?.images" :key="img">
                                    <img :src="img" class="product-img__content">
                                    <span class="btn-remove-img" @click="removeImage(index)" style="display: none;">X</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-flex align-content-start flex-wrap">
                    <div v-if="this.listMarketingChannels?.length" class="">
                        <template v-for="channel in this.listMarketingChannels" :key="channel">
                            <button class="btn" @click="renderLink(channel.id)">
                                <img v-if="channel?.logo" :src="channel?.logo" class="channel-img">
                                <span v-text="channel?.name"></span>
                            </button>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Edit ref="Edit"/>
</template>

<script>
import { mapGetters } from 'vuex';
import { generateAffiliateLink } from '@/utils/utils';
import toastr from 'toastr';
import Edit from './Edit.vue';
export default {
    name: "EditDescription",
    props: [],
    components: {
        Edit
    },
    data(){
        return {
            link: null,
            listMarketingChannels: [],
            product: [],
        }
    },
    computed: {
        ...mapGetters({
            userInfo: 'auth/userInfo',
            productLinkDetail: 'productLinks/productLinkDetail',
            isRerender: 'isRerender',
            ckeditorDataAfter: 'ckeditorDataAfter',
            templateActiveID: 'templateActiveID'
        }),
    },
    created(){
        this.loadDataChannel();
    },
    methods: {
        confirm(){
            this.$emit('confirm');
        },
        show(){
            this.$store.dispatch('setCKEditorDataAfter', null);
            if(this.productLinkDetail?.slug){
                this.loadDataProductDetail();
            }
        },
        async loadDataProductDetail() {
            const res = await this.$store.dispatch('productStore/getProductDetailForPopup', {
                slug: this.productLinkDetail?.slug
            });
            if(res){
                this.product = res;
                this.$store.dispatch('setCKEditorData', this.product?.description ?? '');
                window.jQuery(this.$refs.EditDescription).modal('show');
            }
        },
        async loadDataChannel() {
            const res = await this.$store.dispatch('marketingChannels/getListMarketingChannels');
            if(res){
                this.listMarketingChannels = res;
            }
        },
        async renderLink(channel_id) {
            const campaign_id = this.productLinkDetail?.campaign_id ?? 1;
            const link = generateAffiliateLink(this.productLinkDetail?.slug, this.userInfo?.id, channel_id, campaign_id, this.templateActiveID);
            const result = await this.$store.dispatch('productStore/createShortLink', {link: link});
            if(result?.status && result?.link){
                navigator.clipboard.writeText(result?.link).then(() => {
                    if(this.productLinkDetail?.save){
                        this.save(link, campaign_id, this.productLinkDetail?.target_campaign_contributor_id, this.product?.product_code, channel_id, this.productLinkDetail?.type);
                    }
                    toastr.success(this.$t('copiedLinkProduct', {product_name: this.product?.product_name}));
                }).catch(err => {
                    toastr.error(this.$t('cannotCopyLink'));
                });
            }
        },
        async save(link, campaign_id, target_campaign_contributor_id, code, channel_id, type) {
            const params = {
                target_campaign_contributor_id: target_campaign_contributor_id,
                url: link,
                campaign_id: campaign_id,
                marketing_channel_id: channel_id,
                product_code: code,
                type: type
            };
            await this.$store.dispatch('productLinks/createProductLinkChannel', params);
        },
        async showModalEdit(){
            const CKEditorData = this.templateActiveID == 1 ? (this.product?.description ?? '') : (this.product?.description_advertising ?? '');
            await this.$store.dispatch('setCKEditorData', CKEditorData);
            this.$refs.Edit.show(this.product?.product_code);
        },
        async copyDescription(){
            try {
                const selection = window.getSelection();
                selection.removeAllRanges();

                const contentElement = this.$refs.contentToCopy;
                const imageElement = this.$refs.imageToCopy;

                const range = document.createRange();
                range.selectNode(contentElement);
                selection.addRange(range);
                
                // const rangeImage = document.createRange();
                // rangeImage.selectNode(imageElement);
                // selection.addRange(rangeImage);

                // let linkElement = null;
                // const campaign_id = this.productLinkDetail?.campaign_id ?? 1;
                // const link = generateAffiliateLink(this.productLinkDetail?.slug, this.userInfo?.id, 1, campaign_id, this.templateActiveID);
                // const result = await this.$store.dispatch('productStore/createShortLink', {link: link});
                // if(result?.status && result?.link){
                //     linkElement = document.createElement('a');
                //     linkElement.href = result?.link;
                //     linkElement.innerText = result?.link;
                // }

                const tempContainer = document.createElement('div');
                // tempContainer.innerHTML = contentElement.innerHTML + (linkElement ? linkElement.outerHTML : null) + imageElement.outerHTML;
                tempContainer.innerHTML = contentElement.innerHTML;
                document.body.appendChild(tempContainer);

                const rangeFinal = document.createRange();
                rangeFinal.selectNode(tempContainer);
                selection.removeAllRanges();
                selection.addRange(rangeFinal);
                
                try {
                    document.execCommand('copy');
                    toastr.success(this.$t('copiedSuccess'));
                } catch (err) {
                    toastr.error(this.$t('copiedFailed'));
                }
                selection.removeAllRanges();
                document.body.removeChild(tempContainer);
            } catch (err) {
                toastr.error(this.$t('copiedFailed'));
            }
        },
        hide(){
            window.jQuery(this.$refs.EditDescription).modal('hide');
        },
        changeTemplate(id){
            this.$store.dispatch('setTemplateActiveID', id);
        },
        removeImage(index){
            if (index > -1 && this.product?.images) {
                this.product?.images.splice(index, 1);
            }
        }
    },
    watch: {
        isRerender: {
            handler: function(status) {
                if(status){
                    if(this.templateActiveID == 1){
                        this.product.description = this.ckeditorDataAfter;
                    }else{
                        this.product.description_advertising = this.ckeditorDataAfter;
                    }
                    this.$store.dispatch('setIsRerender', false);
                }
            },
            deep: true
        },
    },

}
</script>

<style scoped lang="scss">
@import "../../../assets/scss/Modal/ModalEditProductDescription.scss";
</style>
