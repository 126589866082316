<template>
    <div id="login-page">
        <div class="row justify-content-center" style="background-color: #f5f5f5;">
            <div class="col-lg-12 login-page__form">
                <div class="logo">
                    <img src="../../../public/logo.svg"/>
                </div>
                <div class="form-title">
                    <h3 class="">{{ $t('login') }}</h3>
                    <span>{{ $t('haveNotAccount') }}
                        <router-link :to="this.$constant.ROUTER.register" class="text-green text-decoration-none">
                            <label>
                                {{ $t('register') }}
                            </label>
                        </router-link>
                    </span>
                </div>
                <div class="row content p-3">
                    <div class="col-xl-7 col-md-7 col-sm-12">
                        <img src="../../../public/logo.png" class="w-100"/>
                    </div>
                    <div class="col-xl-5 col-md-5 col-sm-12">
                        <form @submit="login">
                            <div class="form-content">
                                <div class="form-group">
                                    <label>{{ $t('accountLogin') }}</label>
                                    <InputText :placeholder="$t('enterPhoneOrEmail')" v-model="model.email" :type="'text'" :errors="errors?.email ?? []"/>
                                </div>
                                <div class="form-group position-relative">
                                    <label>{{ $t('password') }}</label>
                                    <InputText :placeholder="$t('enterPassword')" v-model="model.password" :append="true" :type="this.show ? 'text' : 'password'" :errors="errors?.password ?? []"/>
                                    <i :class="this.show ? 'fa fa-eye-slash eye-icon-slash' : 'fa fa-eye eye-icon'"
                                        @click="togglePasswordType"></i>
                                </div>
                                <div class="forget-password text-green">
                                    <router-link :to="this.$constant.ROUTER.forgetPassword" class="text-green text-decoration-none">
                                        <label>
                                            {{ $t('forgetPassword') }}
                                        </label>
                                    </router-link>
                                </div>
                                <button type="submit" class="btn btn-primary">{{ $t('login') }}</button>
                                <div class="divider">
                                    <span>{{ $t('or') }}</span>
                                </div>
                                <LoginGoogle class="btn btn-outline-secondary"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputText from '@/components/Form/InputText.vue';
import LoginGoogle from '@/components/LoginGoogle.vue';
import toastr from "toastr";
export default {
    name: 'LoginScreen',
    components: {InputText, LoginGoogle},
    data() {
        return {
            model: {
                email: '',
                password: '',
            },
            errors: {},
            show: false,
        }
    },
    mounted() {
    },
    methods: {
        togglePasswordType() {
            this.show = !this.show
        },
        async login(e){
            e.preventDefault();
            this.errors = {};
            const res = await this.$store.dispatch('auth/login', {...this.model});
            if(res?.error && res?.message){
                toastr.error(res?.message)
                return;
            }
            if(res?.code === 500 && res?.error){
                this.errors = res?.messages;
                return;
            }
            if(res?.code === 200){
                const active = res?.data?.user?.type == this.$constant.PARTNER.type_business ? true : res?.data?.user?.active;
                this.$store.dispatch('auth/setUserActive', active);
                if(res?.data?.user?.type == this.$constant.PARTNER.type_colloborator){
                    location.replace(this.$constant.ROUTER.dashboardContributor)
                } else if(res?.data?.user?.type == this.$constant.PARTNER.type_partner){
                    location.replace(this.$constant.ROUTER.dashboardPartner)
                } else{
                    location.replace(this.$constant.ROUTER.dashboard)
                }
                return;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/login.scss";
</style>