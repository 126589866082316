import { Module } from 'vuex';
import http from '@/services/http';
import { Networks } from '@/services/Networks';

interface UserState {
    partnersNew: [];
    commission: number | null
    // commission_rate_partner: number | null;
    // commission_rate_contributor: number | null;
}

const state: UserState = {
    partnersNew: [],
    commission: 0,
    // commission_rate_partner: 0,
    // commission_rate_contributor: 0,
};

const userStore: Module<UserState, any> = {
    namespaced: true,
    state,
    mutations: {
        setPartners(state, partnersNew: []) {
            state.partnersNew = partnersNew;
        },
        setCommission(state, commission: number | null) {
            state.commission = commission;
        },
    },
    actions: {
        async getPartners ({ commit }, params) {
            try {
                const response:any = await http.get(Networks.partner.get_partners_by_type, {params: params});
                return response;
            } catch (error: any) {
                return {};
            }
        },
        async addPartners ({ commit }, params) {            
            commit('setPartners', params);
        },
        async getCommission ({ commit }, params) {
            try {
                const response:any = await http.get(Networks.commission.get_commission, {params: params});
                if(response?.result?.data){
                    commit('setCommission', response?.result?.data?.commission_value);
                }
            } catch (error: any) {
                return {};
            }
        },
    },
    getters: {
        partnersNew(state): [] {
            return state.partnersNew;
        },
        getCommission(state): number | null {
            return state.commission;
        },
        // commission_rate_partner(state): number | null {
        //     return state.commission_rate_partner;
        // },
        // commission_rate_contributor(state): number | null {
        //     return state.commission_rate_contributor;
        // },
    },
};

export default userStore;