<template>
    <div class='modal fade' ref="ModalPaymentDetail" id='ModalPaymentDetail' aria-labelledby='ModalPaymentDetail' aria-hidden='true'
        data-bs-backdrop='false' tabindex="-1">
        <div class='modal-dialog modal-lg'>
            <div class='modal-content'>
                <div class='modal-header'>
                    <h1 class='modal-title fs-5' v-if="!this.edit">{{ $t('detailInformationRequestPayment') }}</h1>
                    <h1 class='modal-title fs-5' v-else>{{ $t('actionRequestPayment') }}</h1>
                    <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                </div>
                <div class='modal-body'>
                    <div class='row mb-3'>
                        <div class='col-lg-6 col-md-6 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <label class='left'>{{ $t('requestPaymentCode') }}:</label>
                                <div class='right' v-text="entry?.code"></div>
                            </div>
                        </div>

                        <div class='col-lg-6 col-md-6 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <label class='left'>{{ $t('createdAtTicket') }}:</label>
                                <div class='right' v-text="entry?.created_at ? formatDateCTV(entry?.created_at, 'DD/MM/YYYY') : null"></div>
                            </div>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-6 col-md-12 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <label class='left'>{{ $t('partnerName') }}:</label>
                                <div class='right' v-text="entry?.partner_name"></div>
                            </div>
                        </div>
                        <div class='col-lg-6 col-md-6 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <label class='left'>{{ $t('processingAt') }}:</label>
                                <div class='right' v-text="entry?.payment_date ? formatDateCTV(entry?.payment_date, 'DD/MM/YYYY') : null"></div>
                            </div>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-6 col-md-6 col-sm-6'>
                            <div class='info mt-1 d-flex'>
                                <label class='left'>{{ $t('amount') }}:</label>
                                <div class='right' v-if="!this.updateInfor" v-text="numberFormat(entry?.amount)"></div>
                                <InputNumber :placeholder="$t('enterAmount')" v-else v-model="this.entry.amount" :errors="this.errors?.amount ?? []"/>
                            </div>
                        </div>
                        <div class='col-lg-6 col-md-12 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <label class='left'>{{ $t('status') }}:</label>
                                <div class="right">
                                    <span v-text="entry?.statusName" :class="entry?.status == 1 ? 'label-secondary' : (entry?.status == 2 ? 'label-success' : 'label-danger')"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-12 col-md-12 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <label class='left'>{{ $t('requestPaymentNote') }}:</label>
                                <div class='right' v-text="entry?.content_payment"></div>
                            </div>
                        </div>
                    </div>

                    <div class='row mb-3'>
                        <div class='col-lg-12 col-md-12 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <label class='left'>{{ $t('paymentMethod') }}:</label>
                                <!-- <div class='right' v-text="getMethodPaymentName(entry?.method_payment)"></div> -->
                                <div class='right' v-text="$t('methodPaymentTransfer')"></div>
                            </div>
                        </div>

                    </div>

                    <div class="row mb-3">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class='info mt-1 d-flex'>
                                <label class='left'>{{ $t('bankName') }}:</label>
                                <div class='right'>
                                    <span v-if="!this.edit && !this.updateInfor" v-text="entry?.bank_name"></span>
                                    <InputText :placeholder="$t('bankName')" v-else v-model.trim="entry.bank_name" :errors="this.errors?.bank_name ?? []"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-6 col-md-12 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <label class='left'>{{ $t('accountNumber') }}:</label>
                                <div class='right'>
                                    <span v-if="!this.edit && !this.updateInfor" v-text="entry?.bank_account_number"></span>
                                    <InputText :placeholder="$t('accountNumber')" v-else v-model.trim="entry.bank_account_number" :errors="this.errors?.bank_account_number ?? []"/>
                                </div>
                            </div>
                        </div>
                        <div class='col-lg-6 col-md-12 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <label class='left'>{{ $t('accountOwner') }}:</label>
                                <div class='right'>
                                    <span v-if="!this.edit && !this.updateInfor" v-text="entry?.bank_owner_name"></span>
                                    <InputText :placeholder="$t('accountOwner')" v-else v-model.trim="entry.bank_owner_name" :errors="this.errors?.bank_owner_name ?? []"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-12 col-md-12 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <label class='left'>{{ $t('paymentTicket') }}:</label>
                                <div class='right'>
                                    <InputFile :modelValue="entry?.url_image" @update:modalValue="changeUrlImage" :readonly="!this.edit" :errors="this.errors?.url_image ?? []"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='row mb-3' v-if="entry?.status == this.$constant.PAYMENT.status.reject">
                        <div class='col-lg-12 col-md-12 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <label class='left'>{{ $t('reasonReject') }}:</label>
                                <div class='right' v-text="entry?.reason"></div>
                            </div>
                        </div>
                    </div>
                    <div class='row mt-3' v-if="this.edit">
                        <div class='d-flex justify-content-center mt-3'>
                            <AppPermission :keyPerm="$constant.PERMISSIONS.PER_REQUEST_PAYMENT_MANAGE" :actionPerm="$constant.PERMISSIONS.PER_ACTION_UPDATE">
                                <button class='btn btn-secondary mx-2' @click="reject">
                                    {{ $t('reject') }}
                                </button>
                                <button class='btn btn-primary mx-2' @click="update($constant.PAYMENT.status.complete)">
                                    <span>
                                        {{ $t('approve') }}
                                    </span>
                                </button>
                            </AppPermission>
                        </div>
                    </div>
                    <div class='row mt-3' v-if="this.updateInfor">
                        <div class='d-flex justify-content-center mt-3'>
                            <AppPermission :keyPerm="$constant.PERMISSIONS.PER_REQUEST_PAYMENT_MANAGE" :actionPerm="$constant.PERMISSIONS.PER_ACTION_UPDATE">
                                <button class='btn btn-primary mx-2' @click="funcUpdateInfor()">
                                    <span>
                                        {{ $t('update') }}
                                    </span>
                                </button>
                            </AppPermission>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalRejectRequestPayment ref="ModalRejectRequestPayment" @reload="hide"/>
</template>

<script>
import { numberFormat, clone, getStatusPaymentName, getMethodPaymentName, formatDateCTV } from '@/utils/utils';
import InputNumber from '@/components/Form/InputNumber.vue';
import InputText from '@/components/Form/InputText.vue';
import toastr from 'toastr'
import InputFile from '@/components/Form/InputFile.vue';
import ModalRejectRequestPayment from './ModalRejectRequestPayment.vue'
import AppPermission from '@/components/AppPermission.vue';
export default {
    name: "ModalRequestPaymentDetail",
    emits: ['reload'],
    setup() {
        return { numberFormat, clone, getStatusPaymentName, getMethodPaymentName, formatDateCTV };
    },
    components: { InputText, InputFile, ModalRejectRequestPayment, AppPermission, InputNumber },
    props: {
        action: {
            type: Number,
            required: true
        }
    },
    data(){
        return {
            entry: {},
            edit: false,
            updateInfor: false,
            errors: {}
        }
    },
    methods: {
        show(entry = null, edit = false, updateInfor = false){
            this.errors = [];
            if(entry){
                this.entry = clone(entry);
                this.edit = edit;
                this.updateInfor = updateInfor;
                window.jQuery(this.$refs.ModalPaymentDetail).modal('show');
            }
        },
        hide(){
            this.entry = {};
            this.errors = {};
            this.$emit('reload');
            window.jQuery(this.$refs.ModalPaymentDetail).modal('hide');
        },
        async update(status){
            this.errors = {};
            if(!status){
                return;
            }
            const params = {
                'id': this.entry.id,
                'bank_name': this.entry.bank_name,
                'bank_account_number': this.entry.bank_account_number,
                'bank_owner_name': this.entry.bank_owner_name,
                'url_image': this.entry.url_image,
                'status': status,
            }
            const res = await this.$store.dispatch('payment/updateRequestPayment', params)
            if(res?.data && res?.data?.errors){
                this.errors = res?.data?.errors;
                return;
            }
            if(res?.result?.code !== 200){
                toastr.error(this.$t('msgUpdateError'))
                return;
            }
            toastr.success(this.$t('msgUpdateSuccess'));
            this.hide();
        },

        async funcUpdateInfor(){
            this.errors = {};
            const params = {
                'id': this.entry.id,
                'bank_name': this.entry.bank_name,
                'bank_account_number': this.entry.bank_account_number,
                'bank_owner_name': this.entry.bank_owner_name,
                'amount': this.entry.amount,
            }
            const res = await this.$store.dispatch('payment/updateInforRequestPayment', params)
            if(res?.data && res?.data?.errors){
                this.errors = res?.data?.errors;
                return;
            }
            if(res?.result && res?.result?.error){
                this.errors = res?.result?.messages;
                return;
            }
            if(res?.result?.code !== 200){
                toastr.error(this.$t('msgUpdateError'))
                return;
            }
            toastr.success(this.$t('msgUpdateSuccess'));
            this.$store.dispatch('userStore/getCommission');
            this.hide();
        },
        changeUrlImage(url){
            this.entry.url_image = url;
        },
        reject(){
            this.$refs.ModalRejectRequestPayment.show(this.entry);
        }
    },
    computed: {
        isViewMode() {
            return this.action === 1;
        },
        isEditMode() {
            return this.action === 2;
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../../assets/scss/Modal/ModalPaymentDetail.scss";
</style>
