<template>
    <div class='modal fade' ref="ModalRejectPayment" id='ModalRejectPayment' aria-labelledby='ModalRejectPayment' aria-hidden='true'
        data-bs-backdrop='false' tabindex="-1">
        <div class='modal-dialog modal-lg'>
            <div class='modal-content'>
                <div class='modal-header'>
                    <h1 class='modal-title fs-5'>{{ $t('titleRejectPayment') }}</h1>
                    <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                </div>
                <div class='modal-body'>
                    <div class='row mb-3'>
                        <div class='col-lg-12 col-md-12 col-sm-12'>
                            <div>
                                <label>{{ $t('pleaseEnterReasonRejectPayment') }}</label>
                            </div>
                            <!-- <i>{{ $t('descReasonRejectPayment') }}</i> -->
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-12 col-md-12 col-sm-12'>
                            <InputTextArea :placeholder="$t('enterReasonRejectPayment')" v-model="this.entry.reason" :errors="this.errors?.reason ?? []"/>
                        </div>
                    </div>
                    <div class='row mt-3'>
                        <div class='d-flex justify-content-center mt-3'>
                            <button class='btn btn-secondary mx-2' data-bs-dismiss='modal' aria-label='Close'>
                                {{ $t('cancel') }}
                            </button>
                            <button class='btn btn-warning mx-2' @click="update($constant.PAYMENT.status.reject)">
                                <span>
                                    {{ $t('confirm') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { clone } from '@/utils/utils';
import InputTextArea from '@/components/Form/InputTextArea.vue';
import toastr from 'toastr'
export default {
    name: "ModalRejectPayment",
    emits: ['reload'],
    components: { InputTextArea },
    props: {},
    data(){
        return {
            entry: {},
            errors: {}
        }
    },
    methods: {
        show(entry = null){
            if(entry){
                this.entry = clone(entry);
                window.jQuery(this.$refs.ModalRejectPayment).modal('show');
            }
        },
        hide(){
            this.entry = {};
            this.errors = {};
            this.$emit('reload');
            window.jQuery(this.$refs.ModalRejectPayment).modal('hide');
        },
        async update(status){
            this.errors = {};
            if(!status){
                return;
            }
            const params = {
                'id': this.entry.id,
                'status': status,
                'reason': this.entry.reason
            }
            const res = await this.$store.dispatch('payment/update', params)
            if(res?.data && res?.data?.errors){
                this.errors = res?.data?.errors;
                return;
            }
            if(res?.result?.code !== 200){
                toastr.error(this.$t('msgUpdateError'))
                return;
            }
            toastr.success(this.$t('msgUpdateSuccess'));
            this.hide();
        },
    },
    computed: {
    }
}
</script>

<style scoped lang="scss">
@import "../../../assets/scss/Modal/ModalPaymentDetail.scss";
</style>
