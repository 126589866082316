import axios from 'axios';
import store from '@/store';

const BASE_URL = process.env.VUE_APP_UPLOAD_FILE_URL;
const TOKEN = process.env.VUE_APP_UPLOAD_FILE_SECRET;

const upload = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'multipart/form-data',
        'X-TOKEN': TOKEN
    }
});

upload.interceptors.request.use(
    (config) => {
        store.dispatch('setLoading', true)
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

upload.interceptors.response.use(
    (response) => {
        store.dispatch('setLoading', false)
        if(response?.data){
            return response?.data;
        }
        return response;
    },
    (error) => {
        store.dispatch('setLoading', false)
        if(error?.response?.data){
            return error?.response?.data;
        }
        return Promise.reject(error);
    }
);

export default upload;
