<template>
    <metainfo>
    </metainfo>
    <div id="affiliate-page"></div>
</template>

<script>
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';
import { useStore } from 'vuex';
export default {
    name: 'Affiliate',
    components: {},
    // setup() {
    //     let metaConfig = {};
    //     const route = useRoute();
    //     const urlCurrent = `${window.location.origin}${route.fullPath}`;
    //     if(route?.query?.slug){
    //         const getMetaConfig = async () => {
    //             const store = useStore();
    //             const res = await store.dispatch('productStore/getMetaConfig', {
    //                 slug: route?.query?.slug,
    //                 user_id: route?.query?.contributor,
    //                 description_type: route?.query?.description
    //             });
    //             if(res?.description){
    //                 const tempDiv = document.createElement("div");
    //                 tempDiv.innerHTML = res?.description;
    //                 res.description = tempDiv.textContent || tempDiv.innerText;
    //             }
    //             metaConfig = {
    //                 title: res?.name,
    //                 description: res?.description,
    //                 image: res?.image,
    //             }
    //             useHead({
    //                 title: metaConfig?.title,
    //                 meta: [
    //                     { name: 'description', content: metaConfig?.description },
    //                     { property: 'og:type', content: 'product' },
    //                     { property: 'og:title', content: metaConfig?.title },
    //                     { property: 'og:image', content: metaConfig?.image },
    //                     { property: 'og:image:url', content: metaConfig?.image },
    //                     { property: 'og:description', content: metaConfig?.description },
    //                     { property: 'og:url', content: urlCurrent },
    //                     { name: 'twitter:card', content: 'summary_large_image' },
    //                     { name: 'twitter:title', content: metaConfig?.title },
    //                     { name: 'twitter:image', content: metaConfig?.image },
    //                     { name: 'twitter:description', content: metaConfig?.description },
    //                 ]
    //             });
    //         }
    //         getMetaConfig();
    //     }
    // },
    data() {
        return {
        }
    },
    created() {
        const route = useRoute();
        if(route.query){
            this.updateQuantityClicked(route.query);
        }
    },
    methods: {
        async updateQuantityClicked(query){
            if(!query){
                return;
            }
            const params = {
                'slug': query?.slug,
                'param': {
                    'contributor': query?.contributor,
                    'channel': query?.channel,
                    'campaign': query?.campaign,
                    'referrer': document ? document.referrer: null,
                }
            };            
            const res = await this.$store.dispatch('collaborators/updateQuantityClicked', params);
            if(res?.code === 200 && res?.data?.redirectUrl){
                window.location.href = res?.data?.redirectUrl;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
</style>